import axios from 'axios'
import Bottleneck from 'bottleneck/es5'
import { logRaygunError } from '~/logging'

const limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: 500
})

limiter.on('failed', (err, info) => {
  if (info.retryCount < 3 && err.response != null && err.response.status === 428) {
    return 1000 + Math.round(Math.random() * 250)
  }
})

const instance = axios.create({
  headers: { Pragma: 'no-cache' } // disable IE caching behavior
})

export const preparePayload = payload => {
  if (typeof payload === 'string') {
    return payload.length === 0 ? null : payload
  } else if (payload.constructor === Array) {
    return payload.map(x => preparePayload(x))
  } else if (payload === Object(payload)) {
    return Object.keys(payload).reduce((acc, k) => {
      acc[k] = preparePayload(payload[k])
      return acc
    }, {})
  } else {
    return payload
  }
}

export const formatErrors = function ({ validationErrors, collectionValidationErrors }, collectionPrefix = null) {
  const makeHumanReadable = field =>
    field
      .replace(/([A-Z])/g, ' $1')
      .replace(/[^a-zA-Z0-9-. ]/g, '')
      .split(' ')
      .filter(s => s.length > 0)
      .join(' ')
      .replace('S S N', 'SSN')

  const setFormattedErrors = (errs, existingErrors, prefix = null) => {
    return Object.keys(errs).reduce((acc, k) => {
      const key = prefix === null ? k : `${prefix} ${k}`
      const value = prefix === null ? errs[k] : `${prefix} ${errs[k]}`

      acc[makeHumanReadable(key)] = makeHumanReadable(value)

      return acc
    }, existingErrors)
  }

  let errors = setFormattedErrors(validationErrors, {})

  if (collectionValidationErrors !== undefined) {
    collectionValidationErrors.forEach((item) => {
      errors = setFormattedErrors(item.errors, errors, `${collectionPrefix} ${++item.key}`)
    })
  }

  return errors
}

export const wrapButtonToggle = function (setButtonDisabled, thunk) {
  return async () => {
    try {
      setButtonDisabled(true)
      return await thunk()
    } finally {
      setButtonDisabled(false)
    }
  }
}

export default async function (errorHandler, action) {
  try {
    const res = await limiter.schedule(action, instance)
    await (new Promise((resolve, reject) => setTimeout(resolve, 250)))
    errorHandler(null)
    return res
  } catch (err) {
    if (err.response != null && err.response.status === 428) {
      errorHandler('Please try again')
    } else if (err.response != null && err.response.status === 502) {
      errorHandler('The app is under maintenance, please try again in a few moments.')
    } else {
      errorHandler('Unexpected error: Please refresh the page and try again or call us at 405-717-4900')
    }
    if (err.response != null && err.response.status >= 500) {
      logRaygunError(err)
    }
    throw err
  }
}
